// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ` .Chat_emoji__kl\\+5z {
    width: 40px;
    cursor: pointer;
    position: relative;
  }
  
  .Chat_emoji__kl\\+5z img {
    vertical-align: middle;
  }
  
  .Chat_emojies__yYdOs {
    position: absolute;
    left: 100%;
    transform: translateX(-87%);
    bottom: 100%;
  }`, "",{"version":3,"sources":["webpack://./src/styles/Chat.module.css"],"names":[],"mappings":"CAAC;IACG,WAAW;IACX,eAAe;IACf,kBAAkB;EACpB;;EAEA;IACE,sBAAsB;EACxB;;EAEA;IACE,kBAAkB;IAClB,UAAU;IACV,2BAA2B;IAC3B,YAAY;EACd","sourcesContent":[" .emoji {\n    width: 40px;\n    cursor: pointer;\n    position: relative;\n  }\n  \n  .emoji img {\n    vertical-align: middle;\n  }\n  \n  .emojies {\n    position: absolute;\n    left: 100%;\n    transform: translateX(-87%);\n    bottom: 100%;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"emoji": `Chat_emoji__kl+5z`,
	"emojies": `Chat_emojies__yYdOs`
};
export default ___CSS_LOADER_EXPORT___;
